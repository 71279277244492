/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

          AOS.init({
              startEvent: 'load',
              duration: 850,
              delay: 150,
              offset: 200,
              disable: 'mobile'
          });

          $(window).on('load', function() {
              AOS.refresh();
          });

        var html = $('html');
        // JavaScript to be fired on all pages
          $('.header-menu--wrapper').on('click', function() {
              html.removeClass('loading').toggleClass('nav-open');
          });

          $('.nav-primary-close-link').on('click', function(e) {
              e.preventDefault();
              html.removeClass('nav-open');
              console.log('oheydur');
          });

          var headerHeight = $('.site-header').outerHeight(true);

          if(!$('body').hasClass('page-template-template-front-page')) {
              $('body').css('padding-top', headerHeight);
          }
      },
    // JavaScript to be fired on all pages, after page specific JS is fired
    finalize: function() {
      }
    },
    'page_template_template_front_page': {
      init: function() {

         var owl = $('.front-page-slider');

          owl.owlCarousel({
              items: 1,
              autoplay:true,
              autoplayTimeout:4000,
              smartSpeed: 1500,
              autoplayHoverPause:false,
              animateOut: 'fadeOut',
              mouseDrag: false
          });

          // owl.on('changed.owl.carousel', function(event) {
          //   alert('stuff changed.');
          // });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

          $(window).on('scroll', function() {
              scrollPosition = $(this).scrollTop();
              if (scrollPosition >= 300) {
                  // If the function is only supposed to fire once
                  // $(this).off('scroll');
                  $('.site-header').addClass('nav-updates');

                  // Other function stuff here...
              } else if (scrollPosition < 300) {
                  if($('.site-header').hasClass('nav-updates')) {
                      $('.site-header').removeClass('nav-updates');
                  }
              }
          });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_template_projects': {
      init: function() {
       $('.projects--slider').owlCarousel({
           items: 1,
           stagePadding: 45,
           margin: 5,
           loop: true,
           autoplay:true,
           autoplayTimeout:4000,
           smartSpeed: 2000,
           autoplayHoverPause:true,
           responsive: {
            768: {
                stagePadding: 90,
                margin: 30
            },
             1024: {
                 stagePadding: 120
             },
               1440: {
                 stagePadding: 160
               },
               1920: {
                 stagePadding: 450
               }
           }
       });
      }
    },
   'single_projects': {
        init: function() {
            $('.projects--slider').owlCarousel({
                items: 1,
                stagePadding: 45,
                margin: 5,
                loop: true,
                autoplay:true,
                autoplayTimeout:4000,
                smartSpeed: 2000,
                autoplayHoverPause:true,
                responsive: {
                    768: {
                        stagePadding: 90,
                        margin: 30
                    },
                    1024: {
                        stagePadding: 120
                    },
                    1440: {
                        stagePadding: 160
                    },
                    1920: {
                        stagePadding: 450
                    }
                }
            });
        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
